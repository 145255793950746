import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Icon from './Icon';

const PortfolioOverview = () => {
  const { allPortfolioJson } = useStaticQuery(graphql`
    query PortfolioQuery {
      allPortfolioJson {
        edges {
          node {
            id
            title
            icon
            items {
              title
              link
            }
          }
        }
      }
    }
  `);

  const { edges: portfolioEntries } = allPortfolioJson;

  return (
    <div className="container">
      <div className="row justify-content-around">
        {portfolioEntries.length > 0 && (
          <div className="strip strip-grey">
            <div className="container pt-md-10 pb-md-10">
              <div className="row justify-content-center">
                {portfolioEntries.map(({ node }) => (
                  <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                    <div className="portfolio-column">
                      <Icon
                        iconName={node.icon}
                        style={{ fontSize: '40px', paddingBottom: '10px' }}
                      />
                      <h2 className="portfolio-column-title">{node.title}</h2>
                      {node.items && node.items.length > 0 && (
                        <div className="col-12 mb-2 mt-2 portfolio-column-content">
                          <ul>
                            {node.items.map((childNode) => (
                              <li>
                                {childNode.link ? (
                                  <Link to={childNode.link}>
                                    {childNode.title}
                                  </Link>
                                ) : (
                                  <span>{childNode.title}</span>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioOverview;
