import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

const JobPostingOverview = () => {
  const { jobPostingFiles } = useStaticQuery(graphql`
    query {
      jobPostingFiles: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/careers/.*/" }
          frontmatter: { active: { ne: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              title_short
              teaser_text
              date
            }
          }
        }
      }
    }
  `);

  const { edges: jobPostings } = jobPostingFiles;
  const jobPostingExist = jobPostings.length > 0;

  const callToActionText = jobPostingExist
    ? 'Sieh dir unsere offenen Positionen an!'
    : 'Wir freuen uns auf deine Initiativbewerbung!';
  const text = `Du begeisterst dich für Softwareentwicklung, hast Erfahrung mit
            Microsoft Technologien und suchst nach einer neuen Herausforderung? ${callToActionText}`;

  return (
    <div className="container">
      {/* <div className="col-12">
        <div className="row">
          <h3>{text}</h3>
        </div>
      </div>
      {jobPostingExist && (
        <div className="row justify-content-start pt-2">
          {jobPostings.map(({ node }) => (
            <div key={node.id} className="col-12 col-md-6">
              <div>
                <h2>
                  <Link to={node.fields.slug}>
                    {node.frontmatter.title_short}
                  </Link>
                </h2>
                <p>
                  {`${node.frontmatter.teaser_text} `}
                  <Link to={node.fields.slug}>Mehr</Link>
                </p>
              </div>
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default JobPostingOverview;
